<template>
  <div class="topic">
    <hearder-input
      @getUserList="topicInput"
      title="请输入话题内容"
      buttonShow="ture"
      @diologModul="diologModul"
    ></hearder-input>
    <template>
      <el-table
        :data="getTopicList"
        style="width: 100%"
        :header-cell-style="headClass"
      >
        <el-table-column
          label="排名"
          type="index"
          width="100"
        ></el-table-column>
        <el-table-column label="头像">
          <template slot-scope="scope">
            <div>
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.titleUrl"
                fit="cover"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="简介" prop="introduction"></el-table-column>
        <el-table-column prop="title" label="话题内容"> </el-table-column>
        <!-- <el-table-column prop="dynamicSum" label="点赞数量"> </el-table-column> -->
        <el-table-column
          prop="recentTopicsTime"
          label="最近话题发帖时间"
        ></el-table-column>
        <el-table-column prop="createTime" label="话题创建时间">
        </el-table-column>
        <el-table-column label="操作" align="right" width="300">
          <template slot-scope="scope">
            <div class="operation_list">
              <div>
                <el-select
                  placeholder="请选择是否放入推荐话题栏"
                  @change="selectTopic(scope.row)"
                  v-model="scope.row.isRecommend"
                >
                  <el-option
                    v-for="item in hasTopicList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="delete" @click="diologModul(1, scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>
    <!-- 弹框 -->
    <dialogModul
      :isShowRecordsnum="isShowRecordsnum"
      :dialogVisible="dialogVisible"
      :title="title"
      :textShow="textShow"
      deleteText="是否删除该话题内容？"
      textContent="话题内容"
      :buttonText="buttonText"
      @addDialog="addDialog"
      :row="row"
      @delDialog="delDialog"
      :delTopicShow="delTopicShow"
      :topic="topic"
      ref="clear"
    ></dialogModul>
  </div>
</template>

<script>
import dialogModul from "../../components/dialog/dialog.vue";
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";
import { request } from "../../API/request";
import { debounce, throttle } from "../../components/code/common";
export default {
  components: { hearderInput, Pagination, dialogModul },
  data() {
    return {
      queryInfo: {
        page: 1, //当前页数
        size: 5, //一页显示多个条数据
      },
      total: 0,
      getTopicList: [],
      // 下拉框数组
      hasTopicList: [
        {
          id: 1,
          label: "已放入推荐话题栏",
          value: 1,
        },
        {
          id: 2,
          label: "未放入推荐话题栏",
          value: 0,
        },
      ],
      // 控制弹框的显隐
      dialogVisible: false,
      // 弹框的标题
      title: "",
      // 弹框中删除文字显隐的控制
      textShow: false,
      // 弹框中按钮文字显示内容
      buttonText: "",
      // 删除当前行的内容
      row: {},
      // 删除文本域显示
      delTopicShow: false,
      // 控制弹框中的头像，简历的显隐
      topic: false,
      // 话题人数
      isShowRecordsnum: false
    };
  },
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    // input查询
    topicInput(inputVal) {
      // 发起话题input查询请求
      request({
        url: "/wxDynamicTitle/pageWxDynamicTitle",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size,
          // 话题名称
          title: inputVal,
        },
      }).then((res) => {
        this.total = res.data.total;
        this.getTopicList = res.data.records;
      });
    },
    // 下拉框的回调
    selectTopic: throttle(function (e) {
      let params3 = {
        id: e.id,
        isRecommend: e.isRecommend,
      };
      request({
        url: "/wxDynamicTitle/updateRecommend",
        method: "put",
        data: params3,
      }).then((res) => {
        if (res.code == 200) {
          this.topicInput();
          this.$message.success("修改数据成功");
          return;
        }
        this.$message.error("修改数据失败");
      });
    }, 800),

    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.topicInput();
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.topicInput();
    },
    // 子组件input中的新增按钮 ， 删除按钮 -- 弹框内容的显隐
    diologModul(data, row) {
      if (data == 0) {
        this.topic = true;
        this.title = "新建话题";
        this.textShow = false;
        this.buttonText = "新建";
        this.dialogVisible = true;
        this.isShowRecordsnum = true
      }
      if (data == 1) {
        this.topic = false;
        this.row = row;
        this.title = "删除话题";
        this.delTopicShow = true;
        this.textShow = true;
        this.dialogVisible = true;
        this.buttonText = "删除";
        this.isShowRecordsnum = false
      }
    },
    // 控制弹框显隐事件
    cancel() {
      this.dialogVisible = false;
    },

    // 弹框中新建按钮
    addDialog(dataVal, file, introduction, ShowRecordsnum) {
      let formData = new FormData();
      formData.append("introduction", introduction);
      formData.append("multipartFile ", file);
      formData.append("title ", dataVal);
      formData.append("records ", ShowRecordsnum);
      if (!dataVal || !file || !introduction || !ShowRecordsnum) {
        return this.$message.warning("请把信息填写完整");
      }
      request({
        url: "/wxDynamicTitle/addWxDynamicTitle",
        method: "POST",
        data: formData,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("新增数据成功");
          this.dialogVisible = false;
          this.$refs.clear.clearData();
          // 获取话题内容
          this.topicInput()
          return;
        }
        this.$message.error("新增数据失败");
      });
    },
    // 弹框中删除按钮
    delDialog(dataVal) {
      request({
        url: `/wxDynamicTitle/deletedWxDynamicTitle/${dataVal.id}`,
        method: "delete",
      }).then((res) => {
        if (res.code ==200) {
          this.dialogVisible = false;
          this.delTopicShow = false;
          this.topicInput();
          this.$message.success("删除成功")
        }else{
          this.$message.error("删除失败")
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.topic {
  background-color: #ffffff;
  padding: 24px;
  height: 93%;
  position: relative;
}
.el-table {
  margin-top: 24px;
}
.operation_list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete {
  margin-left: 5%;
  color: #e14f4f;
  cursor: pointer;
  border-left: 1px solid #c2c4cc;
  padding-left: 5%;
}
</style>